import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class Status implements Exportable {


  id:        number = 0;
  version:   number | undefined;
  name:      string | undefined;
  code:      string | undefined;
  color:     string | undefined;

  constructor(){}

}

export class StatusFilter implements QueryFilter {

  name:      string | undefined;
  code:      string | undefined;
  color:     string | undefined;

}


export class StatusExporter implements Exporter  {

  name;
  code;
  color;

  constructor() {

    this.name= {
      nameToShow: 'Nombre'
    };
    this.code = {
      nameToShow: 'Código'
    };
    this.color = {
      nameToShow: 'Color'
    };
  }
}




